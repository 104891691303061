import { useState, useEffect } from 'react';
import './Homepage.css';

function App() {
  const [signatures, setSignatures] = useState(() => 
    parseInt(localStorage.getItem("signatures")) || 1100
  );
  const [submittedEmails, setSubmittedEmails] = useState(() =>
    JSON.parse(localStorage.getItem("submittedEmails")) || []
  );
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    department: ''
  });

  const targetSignatures = 10000;

  useEffect(() => {
    updateProgress();
  }, [signatures,submittedEmails]);

  useEffect(() => {
    getProgress();
  }, []);

  const getProgress = () => {

    
    fetch('https://script.google.com/macros/s/AKfycby0V9ZTArqNYVAZ0hbHRw146tdwEznxz5XJO1CZpubIJDsVG4YnE_19_y0RGvVs8a357w/exec', {
      method: 'GET',
    })
    .then(response => {
      if (!response.ok) throw new Error('Erreur réseau : ' + response.statusText);
    
      return response.json();
    })
    .then(data => {
      console.log('Success:', data);

      setSignatures(1100 + data.progress);
  
    })
    .catch(error => {
      console.error('Error:', error);
      alert("Une erreur s'est produite lors de l'envoi des données. Veuillez réessayer plus tard.");
    });
    
  };

  const updateProgress = () => {
    localStorage.setItem("signatures", signatures);
    localStorage.setItem("submittedEmails", JSON.stringify(submittedEmails));
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, surname, email, phone, department } = formData;

    if (!name || !surname || !email  || !department) {
      alert("Veuillez remplir tous les champs.");
      return;
    }

    // if (submittedEmails.includes(email)) {
    //   alert("Cette adresse email a déjà été utilisée pour signer.");
    //   return;
    // }

    
    const formPayload = new URLSearchParams(formData);
    
    fetch('https://script.google.com/macros/s/AKfycby0V9ZTArqNYVAZ0hbHRw146tdwEznxz5XJO1CZpubIJDsVG4YnE_19_y0RGvVs8a357w/exec', {
      method: 'POST',
      body: formPayload
    })
    .then(response => {
      if (!response.ok) throw new Error('Erreur réseau : ' + response.statusText);
      

      return response.json();
    })
    .then(data => {
      console.log('Success:', data);
      if(data.status=="Duplicate")
      {
        alert("Cette adresse email a déjà été utilisée pour signer.");
        return;
      }
      setSignatures(prev => prev + 1);
      setSubmittedEmails(prev => [...prev, email]);
      setShowPopup(false);
      setShowSuccessPopup(true);
      setFormData({ name: '', surname: '', email: '', phone: '', department: '' });
    })
    .catch(error => {
      console.error('Error:', error);
      alert("Une erreur s'est produite lors de l'envoi des données. Veuillez réessayer plus tard.");
    });
  };

  return (
    <div className="App wrapper">
      <div className="overlay"></div>
      <div className="container">
        <img style={{display:"inline" }} src="https://i.imgur.com/LcFWD4F.png" className="logo" alt="Despii Logo" />
        <h1 style={{ marginBlockStart: '0.67em', marginBlockEnd: '0.67em', fontWeight: 'bold' }}>Une révolution urbaine est en marche !</h1>

        <p>Paris mérite une mobilité plus fluide et plus intelligente.<br />
          Soutenez Despii pour un retour des trottinettes, dans un cadre organisé et sécurisé.
        </p>
        
        <div className="progress-container">
          <div 
            className="progress-bar" 
            style={{ width: `${(signatures / targetSignatures) * 100}%` }}
          ></div>
          <span className="progress-text">
            {signatures.toLocaleString()} / {targetSignatures.toLocaleString()}
          </span>
        </div>

        <button className="cta-button" onClick={() => setShowPopup(true)}>
          Je signe l'appel à citoyen
        </button>
      </div>

      {showPopup && (
        <>
          <div className="popup-overlay" onClick={() => setShowPopup(false)}></div>
          <div className="popup">
            <h3 style={{ marginBlockStart: '1em', marginBlockEnd: '1em', fontWeight: 'bold',fontSize:'1.17em' }}>Rejoignez l'Appel à Citoyen</h3>
            <form onSubmit={handleSubmit}>
              <input
                id="name"
                type="text"
                placeholder="Nom"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              <input
                id="surname"
                type="text"
                placeholder="Prénom"
                value={formData.surname}
                onChange={handleInputChange}
                required
              />
              <input
                id="email"
                type="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              <select
                id="department"
                value={formData.department}
                onChange={handleInputChange}
                required
              >
                <option value="" disabled>Département</option>
                <option value="Paris (75)">Paris (75)</option>
                <option value="Hauts-de-Seine (92)">Hauts-de-Seine (92)</option>
                <option value="Seine-Saint-Denis (93)">Seine-Saint-Denis (93)</option>
                <option value="Val-de-Marne (94)">Val-de-Marne (94)</option>
                <option value="Val-d'Oise (95)">Val-d'Oise (95)</option>
                <option value="Seine-et-Marne (77)">Seine-et-Marne (77)</option>
                <option value="Yvelines (78)">Yvelines (78)</option>
                <option value="Autres">Autres</option>
              </select>
              <input
                id="phone"
                type="tel"
                placeholder="Numéro de téléphone                                         Facultatif"
                value={formData.phone}
                onChange={handleInputChange}
              />
              <button type="submit">Soumettre</button>
            </form>
            <div className="social-icons">
              <a href="https://www.tiktok.com/@gabbok205" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-tiktok"></i>
              </a>
              <a href="https://www.instagram.com/vasyendespii/" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a href="https://x.com/Despii_app" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-twitter"></i>
              </a>
            </div>
          </div>
        </>
      )}

      {showSuccessPopup && (
        <div className="success-popup">
          <h3>Merci pour votre soutien !</h3>
          <p>Votre signature a été enregistrée. Vous recevrez un email de confirmation.</p>
          <button className="close-button" onClick={() => setShowSuccessPopup(false)}>
            &times;
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
