import React from "react";
import { useEffect } from 'react';
import Hero from "../sections/home/Hero";
import OurMission from "../components/OurMission";
import Functionnalities from "../sections/home/Functionnalities";
import PerfectFor from "../sections/home/PerfectFor";
import HowItWorks from "../sections/home/HowItWorks";
import Advantages from "../sections/home/Advantages";
import QAndA from "../sections/home/QAndA";
import CalculateGain from "../sections/home/CalculateGain";
import Footer from "../sections/Footer";

const NewHome = () => {
    
    return (
        <div style={{ color:"white",backgroundColor:"black"}}>
            <Hero />

            <OurMission />

            <Functionnalities />

            <PerfectFor />

            <HowItWorks />

            <CalculateGain />

            <Advantages />

            <QAndA />

            <Footer />
        </div>
)
}

export default NewHome;